<template>
  <router-link
    :to="`/${game.locale}/${game.slug}`"
    class="top-game-card position-relative"
  >
    <div class="top-game-card__background">
      <strapi-image-overlay-component
        v-if="game.topCoverImage"
        fluid
        :data-cy="dataCyName([game.name, 'top-game-cover-img'])"
        :image="game.topCoverImage"
        :alt-fallback="game.name"
        as-background
      />
    </div>

    <div
      v-if="isDiscountedGame"
      class="top-game-card__discount position-absolute end-0 px-2 py-1 fw-bold text-white rounded-start"
    >
      15% OFF
    </div>

    <div
      class="top-game-card__content position-absolute w-100 bottom-0 text-white"
    >
      <div
        class="text-center py-8 h-100 d-flex flex-column justify-content-between"
      >
        <span class="fw-bold overflow-hidden text-truncate px-4 d-block mb-1">{{
          game.name
        }}</span>
        <span
          v-if="game.startConfiguration.prices.length > 0"
          class="price"
          v-html="startingPriceString"
        ></span>
        <span class="btn btn-sm mx-4 btn-primary button">
          {{
            isPreOrder
              ? $t('frontend/preorder-now')
              : $t('landingpage/index/order_now')
          }}
        </span>
      </div>
    </div>
  </router-link>
</template>

<script lang="ts">
import { type StrapiProduct, StrapiProductType } from '~/apollo/types/types';
import StrapiImageOverlayComponent from '../strapi/StrapiImageOverlay.vue';
import dataCyName from '~/helpers/cypress';

export default defineComponent({
  name: 'TopGameCard',
  components: {
    StrapiImageOverlayComponent,
  },
  props: {
    game: {
      type: Object as PropType<StrapiProduct>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const isPreOrder = computed<boolean>(() =>
      timeIsAfterNow(props.game.releaseAt),
    );
    const { formatStrapiPrice, DEFAULT_DISCOUNT } = useNumberMoneyFormatting();

    const startingPrice = computed<string>(() =>
      formatStrapiPrice(props.game.startConfiguration.prices[0]),
    );

    const discountedStartingPrice = computed<string>(() =>
      formatStrapiPrice(
        props.game.startConfiguration.prices[0],
        DEFAULT_DISCOUNT,
      ),
    );
    const isDiscountedGame = computed<boolean>(() => false);

    const startingPriceString = computed<string>(() => {
      let priceString = t('landingpage/price_from', {
        price: startingPrice.value,
      }).toString();
      if (isDiscountedGame.value) {
        // for string "3 days starting at $1.63"
        // we replace the "$1.63" part by custom html
        // should work for all translations
        const customHtml = `<s>${startingPrice.value}</s> <b>${discountedStartingPrice.value}</b>`;
        priceString = priceString.replace(startingPrice.value, customHtml);
      }
      return priceString;
    });

    return {
      isPreOrder,
      dataCyName,
      startingPriceString,
      isDiscountedGame,
    };
  },
});
</script>

<style lang="scss">
@import 'bootstrap/scss/mixins/breakpoints';
@import '../../node_modules/gportal-theme/scss/colors';

.top-game-card {
  $breakpoints: (
    xl: 1200px,
  );

  .button {
    display: none;
  }

  .price {
    display: block;
  }

  @include media-breakpoint-up(xl, $breakpoints) {
    &:hover {
      border: 2px solid $primary;
      box-sizing: border-box;

      .price {
        display: none;
      }

      .button {
        display: block;
      }
    }
  }

  &__content {
    height: 120px;
    white-space: normal;
    background-image: linear-gradient(
      97deg,
      rgba(#364757, 0.6) 15%,
      rgba(#3b3b51, 0.6) 73%,
      rgba(#40304b, 0.6) 102%
    );
  }

  &__discount {
    background: rgba(47, 58, 68, 0.6);
    line-height: 2;
    top: 15% !important;
    background-color: #a92738cf;
  }
}
</style>
