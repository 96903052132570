<template>
  <div class="container">
    <slot name="header">
      <h1
        v-if="displayHeader"
        class="d-flex align-items-center justify-content-center"
      >
        <!--          <img-->
        <!--            alt="Mr. Maintenance"-->
        <!--            class="mx-2"-->
        <!--            height="32"-->
        <!--            width="32"-->
        <!--            src="/sc/images/svgs/mr-maintenance.svg"-->
        <!--          />-->
        {{ $t('landingpage/index/top_games') }}
      </h1>
    </slot>

    <div class="position-relative top-games__box">
      <div
        class="top-games__left shadow"
        :class="{ 'top-games__left-show': !arrivedState.left }"
        @click="scrollToLeft()"
      >
        <fa-icon :icon="faChevronLeft" />
      </div>
      <div
        class="top-games__right shadow"
        :class="{ 'top-games__right-show': !arrivedState.right }"
        @click="scrollToRight()"
      >
        <fa-icon :icon="faChevronRight" />
      </div>
      <div
        ref="games"
        class="top-games__list d-flex align-items-center justify-content-start"
      >
        <gameserver-index-top-game-card
          v-for="product in products"
          :key="product.id"
          :game="product"
          class="top-games__list-item d-block"
          data-cy="top-games-wrapper"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { StrapiProduct } from '~/apollo/types/types';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';

export default defineComponent({
  props: {
    displayHeader: {
      type: Boolean,
      default: true,
    },
    products: {
      type: Array as PropType<StrapiProduct[]>,
      required: true,
    },
  },
  setup(props) {
    const games = ref<HTMLElement>();
    const { x, y, arrivedState } = useScroll(games, { offset: { right: 1 } });

    const scrollToLeft = () => {
      if (games.value == null) return;
      games.value.scrollTo({ left: 0, behavior: 'smooth' });
    };

    const scrollToRight = () => {
      if (games.value == null) return;
      games.value.scrollTo({
        left: games.value.scrollWidth,
        behavior: 'smooth',
      });
    };

    watch(
      () => props.products,
      async () => {
        await nextTick();
        scrollToLeft();
      },
    );

    return {
      faChevronLeft,
      faChevronRight,

      scrollToLeft,
      scrollToRight,
      games,
      y,
      x,
      arrivedState,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'gportal-theme/scss/colors.scss';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'gportal-theme/scss/breakpoints.scss';

.top-games {
  &__box {
    left: 0.75rem;
  }

  &__left,
  &__right {
    display: none;
    cursor: pointer;
    background: $gp-midnight-2;
    color: $gp-rhino;
    padding: 8px;
    position: absolute;
    z-index: 1;
    top: 40%;
  }

  &__left {
    left: -12px;
  }

  &__right {
    right: -12px;
  }

  &__list {
    overflow-x: scroll;
    white-space: nowrap;
    scroll-snap-type: x mandatory;

    &-item {
      width: auto;
      height: 305px;
      min-width: 215px;

      &:nth-child(n) {
        scroll-snap-align: start;
      }

      @include media-breakpoint-down(md) {
        &:nth-child(2n + 1) {
          scroll-snap-align: start;
        }
      }
      @include media-breakpoint-between(md, xl) {
        &:nth-child(3n + 1) {
          scroll-snap-align: start;
        }
      }
      @include media-breakpoint-up(xl) {
        &:nth-child(1n) {
          scroll-snap-align: start;
        }
      }

      @include media-breakpoint-up(xxl) {
        min-width: 20%;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    &__box {
      left: 0;
    }

    &__left-show,
    &__right-show {
      display: block !important;
    }

    &__list {
      overflow-x: hidden;
    }
  }
}
</style>
